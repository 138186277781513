import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Banks from "components/app/school/bank";
import Session from "components/app/school/session";
import Settings from "components/app/school/settings";
import SchoolBranches from "components/app/school/settings/schoolBranches";
import UserManager from "components/app/school/userManager";
import Classes from "components/app/school/classes";
import Subjects from "components/app/school/subjects";
import ManageStaff from "components/app/school/staff/manage";
import ApplicantStaff from "components/app/school/staff/applicants";
import ViewApplicantStaff from "components/app/school/staff/applicants/view";
import SetStaffSalary from "components/app/school/staff/set_salary";
import StaffProfile from "components/app/school/staff/profile";
import StaffPay from "components/app/school/staff/payStaff";
import StaffSubjectAssign from "components/app/school/staff/assignSubject";
import SetSchoolWeekDays from "components/app/school/settings/set/weekdays";
import TimeTable from "components/app/school/timeTable";
import BookletTemplate from "components/app/school/exam/template/booklet";
import SchoolFee from "components/app/school/schoolFee";
import SchoolBooks from "components/app/school/books";
import SportHouse from "components/app/school/sport/house";
import SchoolCurriculum from "components/app/school/curriculum";
import SchoolScoringSystem from "components/app/school/scores";
import SchoolScoreGradeSystem from "components/app/school/scores/grade";
import SchoolAttendance from "components/app/school/attendance";
import SchoolAttendanceStudent from "components/app/school/attendance/student";
import SchoolAttendanceStaff from "components/app/school/attendance/staff";
import SchoolExamTimetable from "components/app/school/exam/timetable";
import SchoolSetExamQuestion from "components/app/school/exam/exam_question";
import SchoolFinance from "components/app/school/finance";
import SchoolDashboard from "components/app/school/dashboard";
import SchoolEmbeddedApp from "components/app/school/embeddedApps";
import WebApp from "components/app/school/embeddedApps/web";

import ManageStudent from "components/app/school/student/manage";
import AddStudent from "components/app/school/student/manage/add";
import MANAGEViewStudent from "components/app/school/student/manage/view";
import EnrolledStudent from "components/app/school/student/enrolled";
import ViewEnrolledStudent from "components/app/school/student/enrolled/view";
import ResultCheckerPin from "components/app/school/student/admin/resultCheckerPin";
import ResultStudent from "components/app/school/resultStudent";
import StudentTermPayment from "components/app/school/student/admin/makePayment";

import AccountAppSetup from "components/app/school/student/admin/account/appSetup";

import KidsAccount from "components/app/school/parent/admin/kidsAccount";

import {default as StaffSchoolAttendanceStudent} from "components/app/school/staff/admin/attendance/student";
import StaffSchoolSetExamQuestion from "components/app/school/staff/admin/exam/questionEntry";
import StaffSchoolExamSubjectSupervision from "components/app/school/staff/admin/exam/supervision";
import StaffScoresEntry from "components/app/school/staff/admin/scoresEntry";
import StaffFormTeacherScoresEntry from "components/app/school/staff/admin/scoresEntry/formTeacher";
import StaffPayment from "components/app/school/staff/admin/payment";
import {default as StaffSchoolCurriculum} from "components/app/school/staff/admin/curriculum";
import StaffSchoolLiveTimetable from "components/app/school/staff/admin/timetable";
import GameGoQuiz from "components/app/school/student/admin/games/goQuiz";


export const AdminRoutes = ({school, user_type=null, user_id=0, user}) => {
    return(
        <>
            <Routes>
                <Route path="dashboard"  element={<SchoolDashboard user_type={user_type} user_id={user_id} user={user} school_id={school.id} currency={school.currency} school={school} />} />
                <Route path="finance" element={<SchoolFinance user_type={user_type} currency={school.currency} user_id={user_id} school={school} school_id={school.id} />} />
                <Route path="bank" element={<Banks school_title={school.title} school_id={school.id} user_id={user_id} />} />
                <Route path="books" element={<SchoolBooks school_title={school.title} currency={school.currency} school_id={school.id} user_id={user_id} />} />
                <Route path="curriculum" element={<SchoolCurriculum school_title={school.title} user_type={user_type} user_id={user_id} school_id={school.id} /> } />
                <Route path="school_fee" element={<SchoolFee currency={school.currency} school_id={school.id} user_id={user_id} />} />
                <Route path="session" element={<Session school_id={school.id} user_id={user_id} />} />
                <Route path="settings" element={<Settings user_id={user_id} school={school} />} />
                <Route path="eapp" element={<SchoolEmbeddedApp school_id={school.id} user_id={user_id} user_type={user_type} school={school} />} />
                <Route path="eapp/web" element={<WebApp school_id={school.id} school_title={school.title} user_id={user_id} user_type={user_type} school={school} />} />
                <Route path="settings/branches" element={<SchoolBranches school_id={school.id} user_id={user_id} user_type={user_type} />} />
                <Route path="settings/set/weekdays" element={<SetSchoolWeekDays school_id={school.id} user_id={user_id} school_title={school.title} working_time_start={school.working_time_start} working_time_end={school.working_time_end} /> } />
                <Route path="admin" element={<UserManager user_type={user_type} headTitle="Admin" user_id={user_id} school_id={school.id} />} />
                <Route path="result_student" element={<ResultStudent user_type={user_type} user_id={user_id} school_id={school.id} school={school} />} />
                <Route path="classes" element={<Classes user_type={user_type} user_id={user_id} school_id={school.id} />} />
                <Route path="subjects" element={<Subjects user_type={user_type} user_id={user_id} school_id={school.id} />} />
                <Route path="scores" element={<SchoolScoringSystem user_type={user_type} user_id={user_id} school_title={school.title} school_id={school.id} />} />
                <Route path="scores/grade" element={<SchoolScoreGradeSystem user_type={user_type} user_id={user_id} school_title={school.title} school_id={school.id} />} />
                <Route path="attendance" element={<SchoolAttendance user_type={user_type} user_id={user_id} school_title={school.title} school_id={school.id} />} />
                <Route path="attendance/student" element={<SchoolAttendanceStudent user_type={user_type} user_id={user_id} school={school} school_title={school.title} school_id={school.id} />} />
                <Route path="attendance/staff" element={<SchoolAttendanceStaff user_type={user_type} user_id={user_id} school={school} school_title={school.title} school_id={school.id} />} />
                <Route path="exam/timetable" element={<SchoolExamTimetable user_type={user_type} user_id={user_id} school={school} school_id={school.id} />} />
                <Route path="exam/set_question" element={<SchoolSetExamQuestion user_type={user_type} user_id={user_id} school={school} school_id={school.id} />} />


                <Route path="staff" element={<ManageStaff user_type={user_type} user_id={user_id} school_id={school.id} school_title={school.title} />} />
                <Route path="staff/pay" element={<StaffPay user_type={user_type} user_id={user_id} school_id={school.id} currency={school.currency} />} />
                <Route path="staff/applied" element={<ApplicantStaff user_type={user_type} user_id={user_id} school_id={school.id} school_title={school.title} />} />
                <Route path="staff/applied/:id" element={<ViewApplicantStaff school_title={school.title} user_type={user_type} user_id={user_id} school_id={school.id} />} />
                <Route path="staff/set_salary" element={<SetStaffSalary currency={school.currency} user_type={user_type} user_id={user_id} school_id={school.id} />} />
                <Route path="staff/subject_assign" element={<StaffSubjectAssign user_type={user_type} user_id={user_id} school_id={school.id} school_title={school.title} />} />
                <Route path="staff/user/:id/*" element={<StaffProfile user_type={user_type} user_id={user_id} school_id={school.id} school_title={school.title} />} />

                <Route path="student" element={<ManageStudent school_title={school.title} user_type={user_type} user_id={user_id} school={school} school_id={school.id} user={user} />} />
                <Route path="student/add" element={<AddStudent school_title={school.title} user_type={user_type} user_id={user_id} school={school} school_id={school.id} user={user} />} />
                <Route path="student/m/:id/*" element={<MANAGEViewStudent user_type={user_type} user_id={user_id} school_id={school.id} school_title={school.title} />} />
                <Route path="student/enrolled" element={<EnrolledStudent school_title={school.title} user_type={user_type} user_id={user_id} school_id={school.id} />} />
                <Route path="student/enrolled/:id" element={<ViewEnrolledStudent  school_title={school.title} user_type={user_type} user_id={user_id} school_id={school.id} />} />

                <Route path="sport/house" element={<SportHouse school_title={school.title} user_type={user_type} user_id={user_id} school_id={school.id} /> } />
                <Route path="timetable" element={<TimeTable user_type={user_type} user_id={user_id} school_id={school.id} /> } />
                <Route path="exam/booklet" element={<BookletTemplate user_type={user_type} user_id={user_id} school_id={school.id} school_title={school.title} school_name={school.name} school_icon={school.icon} /> } />
            </Routes>           
        </>
    )
}

export const StudentRoutes = ({school, user_type=null, user_id=0, user={}, access={}}) => {
    return(
        <>
            <Routes>
                <Route path="account/setup" element={<AccountAppSetup school={school} user_id={user_id} access={access} />} />
                <Route path="make_payment" element={<StudentTermPayment school={school} user={user} school_id={school.id} user_type={user_type} user_id={user_id} access={access} />} />
                <Route path="result_checker_key" element={<ResultCheckerPin school={school} school_id={school.id} user_type={user_type} user_id={user_id} access={access} />} />
                <Route path="game/go_quiz" element={<GameGoQuiz school={school} school_id={school.id} user_type={user_type} user_id={user_id} access={access} />} />
            </Routes>
        </>
    )
}

export const StaffRoutes = ({school, school_branch={}, user_type=null, user_id=0, user, access={}}) => {
    return(
        <>
            <Routes>
                <Route path="payment" element={<StaffPayment school={school} currency={school.currency} school_id={school.id} user_id={user_id} access={access} />} />
                <Route path="curriculum" element={<StaffSchoolCurriculum school={school}  school_title={school.title} user_type={user_type} user_id={user_id} school_id={school.id} school_branch={school_branch} access={access} /> } />
                <Route path="timetable" element={<StaffSchoolLiveTimetable school={school}  school_title={school.title} user_type={user_type} user_id={user_id} school_id={school.id} school_branch={school_branch} access={access} /> } />
                <Route path="attendance/student" element={<StaffSchoolAttendanceStudent school={school} school_id={school.id} user_id={user_id} school_branch={school_branch} access={access} />} />
                <Route path="exam/question_entry" element={<StaffSchoolSetExamQuestion school={school} school_id={school.id} user_id={user_id} school_branch={school_branch} access={access} />} />
                <Route path="exam/subject/supervision" element={<StaffSchoolExamSubjectSupervision school={school} school_id={school.id} user_id={user_id} school_branch={school_branch} access={access} />} />
                <Route path="scores/entry" element={<StaffScoresEntry school={school} school_id={school.id} user_id={user_id} school_branch={school_branch} access={access} />} />
                <Route path="scores/form_master_entry" element={<StaffFormTeacherScoresEntry school={school} school_id={school.id} user_id={user_id} school_branch={school_branch} access={access} />} />
                <Route path="scores/form_master_entry" element={<StaffFormTeacherScoresEntry school={school} school_id={school.id} user_id={user_id} school_branch={school_branch} access={access} />} />
            </Routes>
        </>
    )
}

export const ParentRoutes = ({school={}, user_type=null, user_id=0, user={uid:'', id:0}, access=[]}) => {
    const [state, setState] = useState({id:0, uid:''});
    // const [loading, setLoading] = useState(true);

    useEffect(() => {
        if(access.length){
            // setLoading(true);
            let s = access.filter((item) => item.is_parent_access_app);
            if(s.length === 1){
                setState({...s[0]});
                // setLoading(false);
                return;
            }
            setState({id:0, uid:''});
            // setLoading(false);
            return;
        }
    }, [access]);
    // const func = (arr=[]) => {
    //     if(arr.length > 1){
    //         let s = access.filter((item) => item.is_parent_access_app);
    //         if(s.length === 1){
    //             return s[0];
    //         }
    //         return {id:0, uid:''};
    //     }
    // }
    console.log(state);
    return(
        <>
            <Routes>
                <Route path="account/all_kids" element={<KidsAccount school={school} user={user} user_uid={user.uid} user_type={user_type} user_id={user_id} access={access} />}  />
                <Route path="account/kid/setup" element={<AccountAppSetup school={school} user_type={user_type} user_id={user_id} access={state} />} />
                {state.id && (
                    <>
                    {state.is_setup_app_account ? (
                        <>
                        {state.is_assigned_subjects ? (
                            <>
                            <Route path="make_payment" element={<StudentTermPayment school={school} user={user} school_id={school.id} user_type={user_type} user_id={user_id} access={state} kids={access}/>} />
                            <Route path="account/kid/setup" element={<AccountAppSetup school={school} user_type={user_type} user_id={user_id} access={state} />} />
                            <Route path="game/go_quiz" element={<GameGoQuiz school={school} school_id={school.id} user_type={user_type} user_id={user_id} access={state} />} />
                            <Route path="result_checker_key" element={<ResultCheckerPin school={school} user_uid={user.uid} school_id={school.id} user_type={user_type} user_id={user_id} access={state} />} />
                            </>
                        ) : (
                            <>
                            <Route path="make_payment" element={<StudentTermPayment school={school} user={user} school_id={school.id} user_type={user_type} user_id={user_id} access={state} kids={access}/>} />
                            <Route path="result_checker_key" element={<ResultCheckerPin school={school} user_uid={user.uid} school_id={school.id} user_type={user_type} user_id={user_id} access={state} />} />
                            </>
                        )}
                        </>
                    ) : (
                        <>
                            
                            {/* <Route path="result_checker_key" element={<ResultCheckerPin school={school} user_uid={user.uid} school_id={school.id} user_type={user_type} user_id={user_id} access={state} />} /> */}
                        </>
                    )}
                    </>
                )}
            </Routes>
        </>
    )
}
