import React, { Fragment, useState, useEffect, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { usePaystackPayment } from "react-paystack";
import {OuterWrapper, Wrapper, Content} from './style';
import { NotificationWrapper } from "components/app/school/SmallerComponents/notification/style";


import Loader from "components/app/school/SmallerComponents/loader";
import Notification from "components/app/school/SmallerComponents/notification";
import TABLE from "components/app/school/SmallerComponents/table_html/table";
import TR from "components/app/school/SmallerComponents/table_html/tr";
import TD from "components/app/school/SmallerComponents/table_html/td";
import TH from "components/app/school/SmallerComponents/table_html/th";
import Note from "components/app/school/SmallerComponents/NOTE";
import Redirect from "components/redirectPage";

import { appServices, appServices2, appServices5 } from "api/schoolApp/services";
import { BACKEND_URL, PAYSTACK_LIVE_KEY } from "actions/url";

import { addItemToArray } from "components/app/school/actions/array-utils/Add";
import { updateArray, updateArrayWithArray } from "components/app/school/actions/array-utils/Update";
import { convertNumber } from "components/app/school/actions/money";

import PreloaderLine from 'components/preloaderLine';

const StudentTermPayment = ({access, school, user, user_type,  school_id, user_id, kids}) => {
    const initialErrorState = useMemo(() => ({data:[], title:""}), []);
    const initialSuccessState = useMemo(() => ({title:"", text:""}), []);
    const initialPaymentState = useMemo(() => ({cart:[], id:0}), []);

    const [config, setConfig] = useState({
        reference: '',
        email:user.email,
        amount: 0,
        split:{},
        is_split:false,
        publicKey:PAYSTACK_LIVE_KEY
    });

    const [allSchoolFee, setAllSchoolFee] = useState([]);
    const [backupAllSchoolFee, setBackupAllSchoolFee] = useState([]);

    const [error, setError] = useState(initialErrorState);
    const [success, setSuccess] = useState(initialSuccessState);
    const [unactivatedPaymentState, setUnactivatedPaymentState] = useState(initialPaymentState);
    const [currSession, setCurrSession] = useState({});
    const [schoolBank, setSchoolBank] = useState({});
    // const [allBooks, setAllBooks] = useState({results:[]});
    // const [backupAllBooks, setBackupAllBooks] = useState({results:[]});

    const [preErrorMsg, setPreErrorMsg] = useState('Data loaded with errors... try reloading');

    const [loading2, setLoading2] = useState(true);
    const [loading3, setLoading3] = useState(false);
    const [preloading, setPreloading] = useState(true);
    const [preError, setPreError] = useState(true);
    const [reload, setReload] = useState(true);
    const [exist, setExist] = useState(true);
    const [initCheckoutForm, setInitCheckoutForm] = useState(false);
    const [paymentSuccessful, setPaymentSuccessful] = useState(false);
    const [startPayment, setStartPayment] = useState(false);

    // const nav = useNavigate();

    const initializePayment = usePaystackPayment(config);

    const fetchCurrentSchoolSession = (school_id) => {
        setError({data:[], title:''});
        appServices5.getCurrentSession(school_id).then(res => {
            setCurrSession(res.data);
        }).catch(e => {
            setCurrSession({id:0});
            setPreError(true);
            setError({data:[e.response.data.detail], title:'Session Data Fetch Error'});
        })
    }

    const fetchBank = (sid) => {
        setExist(true);
        setLoading2(true);
        appServices.getSchoolBank(sid).then(res => {
            setSchoolBank(res.data);
            setLoading2(false);
        }).catch(e => {
            setSchoolBank({});
            setExist(false);
            setPreError(true);
            setPreloading(false);
            setLoading2(false);
            return;
        })
    }

    const totAmt = (item={}) => {
        const tot_amt = convertNumber(Math.ceil(item.tot_amt_tp));
        return tot_amt;
    }
    const totAmtClean = (item={}) => {
        const tot_amt = Math.ceil(item.tot_amt_tp)
        return tot_amt;
    }
    const onCartPayment = useCallback((ref) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading3(true);
        if(unactivatedPaymentState.cart.length > 0 || unactivatedPaymentState.is_app_fee_only){
            const data = {
                tot_amt_school:unactivatedPaymentState.tot_amt_school,
                tot_amt_tax: unactivatedPaymentState.tot_amt_tax,
                tot_amt_app_tax:unactivatedPaymentState.tot_amt_app_tax,
                tot_amt_app_tax_sec: unactivatedPaymentState.tot_amt_app_tax_sec,
                paid_by: user.id,
                pay_date: new Date(),
                tot_amt_disct: unactivatedPaymentState.tot_amt_disct
            }
            appServices2.processSchoolPaymentCartPay(unactivatedPaymentState.uid, data).then(res => {
                setPaymentSuccessful(true);
                setLoading3(false);
                setPreloading(false);
            }).catch(e => {
                setError({title:'Payment Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
                setPreloading(false);
                setLoading3(false);
                setReload(true);
            })
            return;
        }
        setError({title:'Payment Error', data:["Cart Not Found"]});
    }, [unactivatedPaymentState, user, initialErrorState, initialSuccessState])

    const checkCartPayment = (item={}, user_id) => {
        if(item.cart.length > 0 || item.is_app_fee_only){
            const data = {
                tot_amt_school:item.tot_amt_school,
                tot_amt_tax: item.tot_amt_tax,
                tot_amt_app_tax:item.tot_amt_app_tax,
                tot_amt_app_tax_sec: item.tot_amt_app_tax_sec,
                paid_by: user_id,
                pay_date: new Date(),
                tot_amt_disct: item.tot_amt_disct
            }
            appServices2.processSchoolPaymentCartPay(item.uid, data).then(res => {
                setPaymentSuccessful(true);

            }).catch(e => {
                
            })
            return;
        }
    }

    const processSchoolFeeToCart = useCallback((arr=[]) => {
        setPreloading(true);
        setPreError(false);
        setError(initialErrorState);
        const filterGeneralItem = arr.filter((item) => item.is_general === true);
        // let data = {
        //     student:enrollState.id, classes:'', sess_added:''
        // }
        appServices2.getUnactivatedPaymentAccess(access.uid).then(result => {
            setUnactivatedPaymentState(result.data);
            const toAmt = totAmtClean(result.data)*100;
            setConfig((prev) => typeof prev === "object" ? {...prev, email:user.email, reference:result.data.ref_id, amount:toAmt} : prev);
            result.data.is_start_checkout ? setInitCheckoutForm(true) : setInitCheckoutForm(false);
            checkCartPayment(result.data, user.id);
            const filterGeneralCartItem = result.data.cart.filter((item) => item.fee_is_general === true);
            if(filterGeneralCartItem.length !== filterGeneralItem.length){
                let cart = [...result.data.cart];
                filterGeneralItem.forEach((item, i) => {
                    setTimeout(() => {
                        let item_data = {
                            payment:result.data.id,
                            type: item.name,
                            item_name: item.name,
                            fee: item.id,
                            added_by: user.id,
                            amt_school: item.fee,
                            book_bought:null
                        }
                        appServices2.insertSchoolPaymentCart(item_data).then(res => {
                            cart.push({...res.data})
                            setUnactivatedPaymentState((prev) => prev && {...prev, cart:cart})
                        }).catch(e => {
                            // setError({data:[e.response.data.detail], title:'Cart Data Insert Error'});
                        })
                    }, i * 100);
                })
                setTimeout(() => {
                    setPreloading(false);
                    setReload(true);
                }, filterGeneralItem.length * 200);
            }
            else{
                const filterOptionalCartItem = result.data.cart.filter((item) => item.fee_is_optional === true);
                // const filterBookCartItem = result.data.cart.filter((item) => item.type === "book");
                if(filterOptionalCartItem.length){
                    setAllSchoolFee((prev) => prev.length && addItemToArray(updateArrayWithArray(prev, addItemToArray(filterOptionalCartItem, {is_checked:true, is_optional:true, is_list_price:false, is_general:false}), 'id', 'fee')));
                }
                // if(filterBookCartItem.length ){
                //     setAllBooks((prev) => prev.results.length && {results:addItemToArray(updateArrayWithArray(prev.results, addItemToArray(filterBookCartItem, {is_checked:true}, "qty", "qty_bought"), "id", "book_bought"))});
                // }
                setPreloading(false);
            }
            // if(!result.data.is_start_checkout){
            //     appServices2.processSchoolPaymentCartCheckout(result.data.uid, user.id, "create").then(res => {
            //         setReload(true);
            //     }).catch(e => {
            //         setPreloading(false);
            //     })
            //     return;
            // }
            return;
        }).catch(e => {
            if(school.is_vos && school.vo_space_rem < 1){
                setPreError(true);
                setPreloading(false);
                setPreErrorMsg('Data loaded with errors, insufficient vospace, please contact the school administrator');
                return;
            }
            // if(school.is_vos && enrollState.is_accepted && enrollState.class_accepted && enrollState.is_enrolled && enrollState.uid && !school.is_start_student_payment){
            //     appServices.insertSchoolStudentFreshPayment(data).then(res => {
            //         setPaymentSuccessful2(true);
            //         setPreloading(false);
            //     }).catch(e => {
            //         setPreError(true);
            //         setPreloading(false);
            //         setPreErrorMsg('Data loaded with errors, something went wrong');
            //     })
            //     return;
            // }
            setError({data:[e.response.data.detail], title:'Payment Data Fetch Error'});
            setPreloading(false);
            setUnactivatedPaymentState(initialPaymentState);
            appServices2.insertSchoolFreshPayment(access.uid);
            setPreError(true);
        })
    }, [school, initialErrorState, initialPaymentState, user, access]);

    const fetchAllSchoolFee = useCallback((sbid, type, csid, cid) => {
        setPreloading(true);
        setError({data:[], title:""});
        setPreError(false);
        appServices.getAllSchoolFees_USERS(sbid, type, csid).then(res => {
            setAllSchoolFee(addItemToArray(res.data, {is_checked:false}));
            setBackupAllSchoolFee(addItemToArray(res.data, {is_checked:false}));
            // const filterBook = res.data.filter((item) => item.is_optional === true && item.is_list_price === true && item.name === "book");
            // if(filterBook.length > 0){
            //     appServices.getAllBooks_FOR_PAYMENT(school.id, cid, sbid).then(book_res => {
            //         setAllBooks({results:addItemToArray(book_res.data, {is_checked:false, qty:0})});
            //         setBackupAllBooks({results:addItemToArray(book_res.data, {is_checked:false, qty:0})})
            //         processSchoolFeeToCart(res.data);
            //     }).catch(e => {
            //         setAllBooks({results:[]});
            //         setBackupAllBooks({results:[]});
            //         processSchoolFeeToCart(res.data);
            //     })
            //     return;
            // }
            processSchoolFeeToCart(res.data);
        }).catch(e => {
            setError({data:[e.response.data.detail], title:'School Fee Data Fetch Error'});
            setPreError(true);
            setPreloading(false);
            setAllSchoolFee([]);
        })
    }, [processSchoolFeeToCart])

    

    const handleOptionalFee = (num=0, checked=false, item={}) => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading3(true);
        if(checked){
            const data = {
                payment:unactivatedPaymentState.id,
                type: item.name,
                item_name: item.name,
                fee: item.id,
                added_by: user.id,
                amt_school: item.fee,
                // school_branch: enrollState.school_branch,
                book_bought:null,
            }
            appServices2.insertSchoolPaymentCart(data).then(res => {
                setAllSchoolFee(updateArray(allSchoolFee, num, {...res.data, is_checked:true, is_optional:true, is_list_price:false, is_general:false}))
                setLoading3(false);
                setSuccess({title:'', text:'Item added to cart'});
            }).catch(e => {
                setError({title:'Cart Insert Error', data:[e.response.data.detail]});
                setLoading3(false);
            })
            return;
        }
        appServices2.deleteSchoolPaymentCart(item.uid, user.id).then(res => {
            setAllSchoolFee(updateArray(allSchoolFee, num, {...backupAllSchoolFee[num], is_checked:false}));
            setLoading3(false);
            setSuccess({title:'', text:'Item removed from cart'});
        }).catch(e => {
            setError({title:'Cart Item Delete Error', data:[e.response.data.detail]});
            setLoading3(false);
        })
    }


    const onInitializeCartPayment = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setLoading3(true);
        const schAmt = Math.ceil(unactivatedPaymentState.tot_amt_school) * 100;
        unactivatedPaymentState.is_app_fee_only ? setConfig((prev) => { return prev}) :  setConfig((prev) => typeof prev === "object" ? {...prev, split:JSON.stringify({ type:"flat", bearer_type:'account', subaccounts:[{ subaccount:schoolBank.p_subaccount_code, share:schAmt}] }), is_split:true} : {...prev})
        setTimeout(() => { 
            setStartPayment(true);
        })
    }
    const onBackToCart = () => {
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setPreloading(true);
        appServices2.processSchoolPaymentCartCheckout(unactivatedPaymentState.uid, user_id, "remove").then(res => {
            setReload(true);
        }).catch(e => {
            setError({title:'Cart Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
            setPreloading(false);
        })
    }
    const onClose = () => {
        setReload(true);
    }
    const onCartCheckout = (e) => {
        e.preventDefault();
        setError(initialErrorState);
        setSuccess(initialSuccessState);
        setPreloading(true);
        if(unactivatedPaymentState.cart.length > 0){
            appServices2.processSchoolPaymentCartCheckout(unactivatedPaymentState.uid, user.id, "create").then(res => {
                setSuccess({title:'', text:res.data.message});
                setReload(true);
            }).catch(e => {
                setError({title:'Cart Checkout Error', data:[e.response.data.detail ? e.response.data.detail : 'Something went wrong']});
                setInitCheckoutForm(false);
                setPreloading(false);
            })
            return;
        }
        setError({title:'Cart Checkout Error', data:["Cart Not Found"]});
    }

    useEffect(() => {
        if(school_id && reload && school && access){
            fetchCurrentSchoolSession(school_id);
            fetchBank(school_id);
            fetchAllSchoolFee(access.school_branch_id, 'returning', access.class_section_school);
            setReload(false);
        }
        
    }, [school_id, reload, access, school, fetchAllSchoolFee]);

    useEffect(() => {
        // if(startPayment && unactivatedPaymentState.is_app_fee_only && config.is_split){
        //     initializePayment(onCartPayment, onClose);
        //     setLoading3(false);
        //     setStartPayment(false);
        //     return;
        // }
        if(startPayment && unactivatedPaymentState.cart.length > 0 && config.is_split){
            initializePayment(onCartPayment, onClose);
            setLoading3(false);
            setStartPayment(false);
            return;
        }
        if(startPayment){
            setLoading3(false);
            setError({title:'Payment Error', data:["Cart Not Found"]});
            setStartPayment(false);
            return;
        }
    }, [unactivatedPaymentState, onCartPayment, startPayment, initializePayment, config])

    const errorHandler = error.data.map((item, index) => {
        return(<Notification type="error" text={item} key={index} title={error.title} />)
    })

    const insertTDData = () => {
        const filterGeneralItem = unactivatedPaymentState.cart.filter((item) => item.fee_is_general === true);
        const filterOptionalItem = allSchoolFee.filter((item) => item.is_optional === true);
        const filterOptionalItem2 = allSchoolFee.filter((item) => item.is_optional === true && item.is_list_price === false);
        // const filterOptionalBookItem = allSchoolFee.filter((item) => item.is_optional === true && item.is_list_price === true && item.name === "book");

        return(
            <>
                {filterGeneralItem.length > 0 && (
                    <>
                        <TR>
                            <TD colspan={2} classname={"align-center text-upper fw-700"} font_size="small">General Items</TD>
                        </TR>
                        {filterGeneralItem.map((item, i) => {
                            return (
                            <Fragment key={i}>
                                <TR>
                                    <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.item_name === "school" ? 'School Tuition' : item.item_name} fee</TD>
                                    <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(item.amt_school)}</TD>
                                    <TD style={{border:'1px solid #ccc'}}><input type={"checkbox"} defaultChecked disabled/></TD>
                                </TR>
                            </Fragment>
                            )
                        })}
                        
                    </>
                )}
                {parseInt(unactivatedPaymentState.tot_amt_app_tax) > 0 && (
                    <TR>
                        <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>App fee</TD>
                        <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(unactivatedPaymentState.tot_amt_app_tax)}</TD>
                        <TD style={{border:'1px solid #ccc'}}><input type={"checkbox"} defaultChecked disabled/></TD>
                    </TR>
                )}
                
                {filterOptionalItem.length > 0 && (
                    <>
                        <TR>
                            <TD colspan={2} classname={"align-center text-upper fw-700"} font_size="small">Optional Items</TD>
                        </TR>
                        {filterOptionalItem2.map((item, i) => {
                            return(
                                <Fragment key={i}>
                                    <TR>
                                        <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.is_checked ? item.item_name : item.name} fee</TD>
                                        <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(item.is_checked ? item.amt_school : item.fee)}</TD>
                                        <TD style={{border:'1px solid #ccc'}}><input type={"checkbox"} name="optional" onChange={() => handleOptionalFee(item.num, !item.is_checked, {...item})} checked={item.is_checked} /></TD>
                                    </TR>
                                </Fragment>
                            )
                        })}
                        {/* {filterOptionalBookItem.length > 0 && (
                            <>
                            {allBooks.results.map((item, i) => {
                                return(
                                    <Fragment key={i}>
                                        <TR>
                                            <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize"}>BOOK - {item.is_checked ? item.item_name : item.name}</TD>
                                            <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(item.is_checked ? item.amt_school : item.price)}</TD>
                                            <TD style={{border:'1px solid #ccc'}}>
                                                <input type={"checkbox"} name="book" onChange={() => handleBookFee(item.num, !item.is_checked, {...item})} checked={item.is_checked} />   
                                                {item.is_checked && (
                                                    <>
                                                    {item.qty > 0 && (
                                                        <input type={"number"} style={{width:'40px'}} onChange={(e) => handleBookFee(item.num, e, {...item})} className="f-c font-very-small" name="qty" value={item.qty} placeholder="Qty" />
                                                    )}
                                                    </>
                                                )}
                                            </TD>
                                        </TR>
                                    </Fragment>
                                )
                            })}
                            </>
                        )} */}
                        
                    </>
                )}
                {/* <TR>
                    <TD colspan={3} style={{border:'1px solid #555', color:'red'}} classname={"fw-700 align-left"}><i>tax = {school.curr_symbol}{convertNumber(tot_tax)}</i></TD>
                </TR> */}
            </>
        )
    }

    const insertCartTTData = () => {
        // let tot_app_amt = parseFloat(unactivatedPaymentState.tot_amt_app_tax);
        let tot_tax = parseFloat(unactivatedPaymentState.tot_amt_tax)
        let tot = parseFloat(unactivatedPaymentState.tot_amt_tp) - tot_tax
        let tot_amt = tot + tot_tax;
        let tot_amt_disct = parseFloat(unactivatedPaymentState.tot_amt_disct)
        const filterFee = unactivatedPaymentState.cart.filter((item) => item.fee)
        const filterOthers = unactivatedPaymentState.cart.filter((item) => !item.fee)
        return(
            <>
                {filterFee.map((item, i) => {
                    const amt = item.is_quantifiable ? item.amt_school * item.qty_bought : item.amt_school;
                    return(
                        <Fragment key={i}>
                            <TR>
                                <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.fee ? `${item.item_name === "school" ? 'School Tuition' : item.item_name} fee` : `${item.type.toUpperCase()} - ${item.item_name === "sport" ? 'Sport Tuition' : item.item_name}`}</TD>
                                <TD style={{border:'1px solid #ccc'}}>{item.is_quantifiable ? item.qty_bought : '-'}</TD>
                                <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(amt)}</TD>
                            </TR>
                        </Fragment>
                    )
                })}
                {!unactivatedPaymentState.is_vo_space_enabled && (
                    <TR>
                        <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>Service fee</TD>
                        <TD style={{border:'1px solid #ccc'}}>{'-'}</TD>
                        <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(unactivatedPaymentState.tot_amt_app_tax)}</TD>
                    </TR>
                )}
                
                {filterOthers.map((item, i) => {
                    const amt = item.is_quantifiable ? item.amt_school * item.qty_bought : item.amt_school;
                    return(
                        <Fragment key={i}>
                            <TR>
                                <TD style={{border:'1px solid #ccc'}} classname={"text-capitalize align-center"}>{item.fee ? `${item.item_name} fee` : `${item.type.toUpperCase()} - ${item.item_name}`}</TD>
                                <TD style={{border:'1px solid #ccc'}}>{item.is_quantifiable ? item.qty_bought : '-'}</TD>
                                <TD style={{border:'1px solid #ccc'}} classname={"fw-600"}>{school.curr_symbol}{convertNumber(amt)}</TD>
                            </TR>
                        </Fragment>
                    )
                })}
                <TR>
                    <TD ></TD>
                    <TD style={{border:'1px solid #777'}} colspan={2} classname={"fw-700"}>T = {school.curr_symbol}{convertNumber(tot)} </TD>
                </TR>
                <TR>
                    <TD colspan={3} style={{border:'1px solid #555', color:'red'}} classname={"fw-700 align-left"}><i>TAX = {school.curr_symbol}{convertNumber(Math.ceil(tot_tax))}</i></TD>
                </TR>
                {tot_amt_disct > 0 && (
                    <TR>
                        <TD colspan={3} style={{border:'1px solid #555', color:'rgb(25, 245, 40)'}} classname={"fw-700 align-left"}><i>DISCT = -{school.curr_symbol}{convertNumber(Math.ceil(tot_tax))}</i></TD>
                    </TR>
                )}
                <TR>
                    <TD ></TD>
                    <TD style={{border:'1px solid #777'}} colspan={2} classname={"fw-700 align-center"}><i className="font-very-small">Total Amount</i> <br /> = <br />
                        {school.curr_symbol}{convertNumber(Math.ceil(tot_amt))} 
                    </TD>
                </TR>
            </>
        )
    }

    const statBtn = (stat={is_parent_access_app:false, is_setup_app_account:false, is_assigned_subjects:false, is_cs_paid:false}, type="", id="") => {
        if(type === "text"){
            return(
                <>
                    <span className='disp-block fw-500 text-capitalize app-text-color font-super-small'> Current Term Payment <span className={`fw-700 ${stat.is_cs_paid ? 'fas fa-check green' : 'fas fa-times red'}`}></span> </span> <br />
                </>
            )
        }
    }
    console.log(kids)
    return(
        <>
        <NotificationWrapper>
            {error.title && (errorHandler)}
            {success.text && (<Notification type="success" timer={1000 * 30} text={success.text} title={success.title} click_text={success.click_text} handleClick={success.handleClick} />)}
        </NotificationWrapper> 
        {preloading && <PreloaderLine />}
        <Content>
            <div className="f-70">
            {!preloading && (
            <>
            {!loading2 && (
                <>
                {exist ? (
                    <>
                    {!preError ? (
                        <>  
                        {!initCheckoutForm ? (
                            <>
                                <OuterWrapper>
                                <Wrapper>
                                    {loading3 && <Loader />}
                                    <span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700', transform:'translateY(-10px)'}}>{school.title} {currSession.session} {currSession.term_name} Term School Fee Payment </span>
                                    {user_type === "parent" && (<span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700'}}>FOR {access.is_self_sponsored ? `${access.user_last_name} ${access.user_first_name},` : `${access.last_name} ${access.first_name},`} <br /> </span>)}
                                    <div className="disp-block align-center">
                                        <img className="br-circle" alt={'student profile'} style={{width:'10rem', height:'9rem', objectFit:'cover'}} src={access.is_self_sponsored ? `${BACKEND_URL}${access.user_profile_pic}` : `${BACKEND_URL}${access.profile_pic}`} />
                                    </div>
                                    <form onSubmit={e => onCartCheckout(e)}>
                                    <Content>
                                        
                                            <div style={{width:'100%'}}>
                                                <TABLE >
                                                    <thead>
                                                        <TR style={{backgroundColor:'#000'}}>
                                                            <TH style={{width:'89%', color:'#ccc'}}>Item</TH>
                                                            <TH style={{color:'#ccc'}}>Amount</TH>
                                                            <TH style={{color:'#ccc'}}>Act</TH>
                                                        </TR>
                                                    </thead>
                                                    <tbody>
                                                        {insertTDData()}
                                                    </tbody>
                                                </TABLE>
                                            </div>
                                            <Note>To add item to cart, check the checkbox</Note>
                                            <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-100" style={{marginTop:'15px'}}>
                                                <button type="submit" className="btn hover-a br-5 shadow rem1_ bg-black hover-opacity font-small" style={{color:'white', padding:'10px'}}>Proceed to Checkout <i className="fas fa-arrow-right"></i></button>
                                            </motion.div>
                                            <div className="disp-block" style={{marginTop:'20px'}}>
                                                {allSchoolFee.filter((it) => it.is_general && it.name === "school").map((item, i) => (
                                                    <div className="disp-flex wrap jc-stretch" key={i}>
                                                        <div className="padd-4px">
                                                            <span className="fw-600 font-very-small app-text-color text-capitalize">School Tuition Fee:</span>
                                                        </div>
                                                        <div className="padd-4px">
                                                            <span className="fw-500 font-very-small app-text-color">{item.description ? item.description : 'Payment for educational services to be recieved'}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="disp-flex wrap jc-stretch">
                                                    <div className="padd-4px">
                                                        <span className="fw-600 font-very-small app-text-color text-capitalize">Service Fee:</span>
                                                    </div>
                                                    <div className="padd-4px">
                                                        <span className="fw-500 font-very-small app-text-color">This covers payment for the initial result checking key card and other app infrastructures </span>
                                                    </div>
                                                </div>
                                                {allSchoolFee.filter((it) => it.is_general && it.description && it.name !== "school" ).map((item, i) => {
                                                    return(
                                                        <Fragment key={i}>
                                                            <div className="disp-flex wrap jc-stretch">
                                                                <div className="padd-4px">
                                                                    <span className="fw-600 font-very-small app-text-color text-capitalize">{item.name} Fee:</span>
                                                                </div>
                                                                <div className="padd-4px">
                                                                    <span className="fw-500 font-very-small app-text-color">{item.description}</span>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                })}

                                            </div>
                                    </Content>
                                    </form>
                                </Wrapper>
                                </OuterWrapper>
                                </>
                            ) : (
                                <>
                                {!paymentSuccessful ? (
                                    <>
                                    <OuterWrapper>
                                    <Wrapper>
                                        {loading3 && <Loader />}
                                        <span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700', transform:'translateY(-10px)'}}>{school.title} {currSession.session} {currSession.term_name} Term School Fee Payment </span>
                                        {user_type === "parent" && (<span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700'}}>FOR {access.is_self_sponsored ? `${access.user_last_name} ${access.user_first_name},` : `${access.last_name} ${access.first_name},`} <br /> </span>)}
                                        <div className="disp-block align-center">
                                            <img className="br-circle" alt={'student profile'} style={{width:'10rem', height:'9rem', objectFit:'cover'}} src={access.is_self_sponsored ? `${BACKEND_URL}${access.user_profile_pic}` : `${BACKEND_URL}${access.profile_pic}`} />
                                        </div>
                                        <form onSubmit={e => onInitializeCartPayment(e)}>
                                        <Content>
                                            <div style={{width:'100%'}}>
                                                <TABLE >
                                                    <thead>
                                                        <TR style={{backgroundColor:'var(--appBgColor)'}}>
                                                            <TH style={{width:'89%', color:'#ccc'}}>Item</TH>
                                                            <TH style={{color:'#ccc'}}>Qty</TH>
                                                            <TH style={{color:'#ccc'}}>Amount</TH>
                                                        </TR>
                                                    </thead>
                                                    <tbody>
                                                        {insertCartTTData()}
                                                    </tbody>
                                                </TABLE>
                                                <div style={{display:'flex', flexWrap:'wrap', width:'100%'}}>
                                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-50" style={{marginTop:'15px', flex:'0 0 50%', maxWidth:'50%'}}>
                                                        <button type="button" onClick={() => onBackToCart()} className="btn fw-500 hover-a br-5 shadow rem1_ bg-black" style={{padding:'7px', color:'#ddd'}}> <i className="fas fa-arrow-left"></i> Back </button>
                                                    </motion.div>
                                                    <motion.div initial={{opacity:0}} animate={{opacity:1, duration:0.5}}  className="form-group align-center f-50" style={{marginTop:'15px', flex:'0 0 50%', maxWidth:'50%'}}>
                                                        <button type="submit" className="mybtn br-5 font-slightly-small fw-600 app-bg-color font-med" style={{ color:'#eee', padding:'10px'}}>Pay {`${school.curr_symbol}${totAmt(unactivatedPaymentState)}`}  <i className="fas fa-money-alt"></i> </button>
                                                    </motion.div>
                                                </div>
                                            </div>
                                        </Content>
                                        </form>
                                    </Wrapper>
                                    </OuterWrapper>
                                    </>
                                ) : (
                                    <>
                                    <OuterWrapper>
                                        <Wrapper>
                                            <span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700', transform:'translateY(-10px)'}}>{school.title} {currSession.session} {currSession.term_name} Term School Fee Payment </span>
                                            {user_type === "parent" && (<span className="font-small align-center text-upper" style={{display:'block', color:'black', fontWeight:'700'}}>FOR {access.is_self_sponsored ? `${access.user_last_name} ${access.user_first_name},` : `${access.last_name} ${access.first_name},`} <br /> </span>)}
                                            <div className="disp-block align-center">
                                                <img className="br-circle" alt={'student profile'} style={{width:'10rem', height:'9rem', objectFit:'cover'}} src={access.is_self_sponsored ? `${BACKEND_URL}${access.user_profile_pic}` : `${BACKEND_URL}${access.profile_pic}`} />
                                            </div>
                                            <Content>

                                            <h1 className="disp-block font-big green" style={{color:'green'}}>Payment Has Been Made Successfully... :)</h1>
                                            </Content>
                                        </Wrapper>
                                    </OuterWrapper>
                                    
                                    </>
                                )}
                                </>
                            )}
                                
                            </>
                        ) : (
                            <>
                            <Redirect error title="400"  btn1 btn1_title="Reload" onClickBtn1={() => setReload(true)}>{preErrorMsg}</Redirect>
                            </>
                        )}
                        </>
                    ) : (
                        <>
                        
                        </>
                    )}
                    </>
                )}
                </>
            )}
            </div>
            <div className="f-30 ">
                <OuterWrapper>
                    <Wrapper>
                        <h3 className="app-text-color">Other Kid(s)</h3>

                        <div className="disp-block">
                            <div className="disp-block">
                                <span className="font-super-small app-text-color fw-500">Click <Link to={`/${school.title}/account/all_kids`} className="font-very-small">here</Link>, to begin the payment proccess for any of your lovely children</span>
                            </div>
                            <div className='flex-100'>
                                    <TABLE>
                                        <thead>
                                            <TR>
                                                <TH style={{width:'60%'}}>Kid's Name</TH>
                                                {/* <TH>School - Current Class</TH> */}
                                                <TH>Status</TH>
                                            </TR>
                                        </thead>
                                        <tbody>
                                            {kids.filter((item) => item.school_id === school.id).map((item, i) => {
                                                return(
                                                    <Fragment key={i}>
                                                        <TR>
                                                            <TD classname={"text-capitalize align-center"}>{item.last_name} {item.first_name}</TD>
                                                            {/* <TD classname={"text-capitalize"}>{item.school_title} - {item.class_name}</TD> */}
                                                            <TD>{statBtn({is_parent_access_app:item.is_parent_access_app, is_setup_app_account:item.is_setup_app_account, is_assigned_subjects:item.is_assigned_subjects, is_cs_paid:item.is_cs_paid}, "text")}</TD>
                                                        </TR>
                                                    </Fragment>
                                                )
                                            })}
                                        </tbody>
                                    </TABLE>
                                </div>
                        </div>
                    </Wrapper>
                </OuterWrapper>
            </div>
        </Content>

        </>
    );
}

export default StudentTermPayment;